<template>
  <transition name="selectMask-show">
    <div class="selectMask" v-if="showSelectMask">
      <div class="pullWrap">
        <div class="pullWrapbg">
          <!-- <van-area
            title="请选择所在地区"
            :area-list="areaList"
            visible-item-count="5"
            value="230500"
            @confirm="closeAreaSelect(1)"
            @cancel="closeAreaSelect(0)"
            @change="areaSelect"
            swipe-duration="300"
            :columns-placeholder="['请选择', '请选择', '请选择']"
          /> -->
          <van-picker
            ref="selectArea"
            title="请选择所在地区"
            show-toolbar
            :columns="selectList"
            :loading="loading"
            visible-item-count="5"
            @confirm="closeAreaSelect(1)"
            @cancel="closeAreaSelect(0)"
            @change="areaSelect"
            swipe-duration="300"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Picker from "vant/lib/picker";
import "vant/lib/picker/style";
export default {
  name: "areaLayer",
  data() {
    return {
      showSelectMask: false,
      loading: false,
      selectList: [],
      cities: {}, //  存储已从后端读取城市数据
      districts: {}, //  存储已从后端读取区县数据
      listName: {
        0: "provinces",
        1: "cities",
        2: "districts"
      }
    };
  },
  props: [
    "showSelect", //  显示选择弹窗
    "selectArea" //  已经选中地区id[1,2,3](省id,市id,区id)
  ],
  components: {
    [Picker.name]: Picker
  },
  watch: {
    showSelect(val) {
      this.showSelectMask = val;
      //  打开时设置默认选择
      if (val && this.selectArea.length == 3) {
        //  匹配省
        let curProvinceIndex = this.findListIndex(
          this.selectList[0]?.values,
          this.selectArea[0]
        );
        this.$nextTick(() => {
          if (curProvinceIndex > -1) {
            this.$refs["selectArea"].setColumnIndex(0, curProvinceIndex);
            this.getCity(this.selectArea[0], "setDefault");
            this.getDistrict(this.selectArea[1], "setDefault");
          }
        });
      }
    }
  },
  created() {
    this.getProvince();
  },
  methods: {
    //  关闭弹窗
    closeAreaSelect(type) {
      if (!!type) {
        //  确认
        let selectArea = this.$refs["selectArea"],
          tempAreaArr = selectArea.getValues();
        if (tempAreaArr.length == 3 && !tempAreaArr.some((item) => !item.id)) {
          //  关闭
          this.$emit("close", tempAreaArr);
        } else {
          this.$toast({
            message: "请选择完整的地区",
            position: "bottom"
          });
        }
      } else {
        //  关闭
        this.$emit("close", null);
      }
    },
    //  选择区域变化（dom, 当前选中结果, 变化index）
    areaSelect(el, result, selectIndex) {
      if (selectIndex === 0) {
        //  省变化了
        let selectProvinceId = result[selectIndex].id;
        this.selectList.splice(2, 1);
        if (!selectProvinceId) {
          this.selectList.splice(1, 1);
        } else {
          this.getCity(selectProvinceId);
        }
      } else if (selectIndex === 1) {
        //  市变化了
        let selectCityId = result[selectIndex].id;
        if (!selectCityId) {
          this.selectList.splice(2, 1);
        } else {
          this.getDistrict(selectCityId);
        }
      }
    },
    //  获取省
    getProvince() {
      let temp = [];
      this.loading = true;
      this.request
        .get("/api/scm/province/list", {
          page: 1,
          size: 100
        })
        .then((res) => {
          const provinceList = res || [];
          provinceList.forEach((item) => {
            temp.push({
              text: item.name,
              id: item.id,
              adcode: item.adcode
            });
          });
          this.$set(this.selectList, 0, this.initListColumn(temp));
          this.loading = false;
        });
    },
    //  获取城市
    getCity(provinceId, type) {
      this.loading = true;
      if (!!this.cities[provinceId]) {
        this.loadList(
          1,
          provinceId,
          !!type ? { selectAreaId: this.selectArea[1] } : null
        );
      } else {
        this.request
          .get("/api/scm/city/list", {
            provinceId: provinceId
          })
          .then((res) => {
            let temp = [];
            const cityList = res || [];
            let tempIndex = null;
            cityList.forEach((item, index) => {
              temp.push({
                text: item.name,
                id: item.id,
                adcode: item.adcode
              });
              if (!!this.selectArea[1] && item.id == this.selectArea[1]) {
                tempIndex = index;
              }
            });
            this.cities[provinceId] = temp;
            this.loadList(1, provinceId, !!type ? { index: tempIndex } : null);
          });
      }
    },
    //  获取区
    getDistrict(cityId, type) {
      this.loading = true;
      if (!!this.districts[cityId]) {
        this.loadList(
          2,
          cityId,
          !!type ? { selectAreaId: this.selectArea[2] } : null
        );
      } else {
        this.request
          .get("/api/scm/district/list", {
            cityId: cityId
          })
          .then((res) => {
            let temp = [];
            const districtsList = res || [];
            let tempIndex = null;
            districtsList.forEach((item, index) => {
              temp.push({
                text: item.name,
                id: item.id,
                adcode: item.adcode
              });
              if (!!this.selectArea[2] && item.id == this.selectArea[2]) {
                tempIndex = index;
              }
            });
            this.districts[cityId] = temp;
            this.loadList(2, cityId, !!type ? { index: tempIndex } : null);
          });
      }
    },
    //  装载数据
    loadList(columnIndex, id, setDefaultIndexObj) {
      this.$set(
        this.selectList,
        columnIndex,
        this.initListColumn(
          this[this.listName[columnIndex]][id],
          setDefaultIndexObj
        )
      );
      this.loading = false;
    },
    //  初始化选择栏
    initListColumn(add, setDefaultIndexObj) {
      //  返回一个初始选择列
      let defaultItem = {
        text: "请选择",
        id: 0
      };
      let defaultIndex = 0;
      if (!!setDefaultIndexObj) {
        if (!!setDefaultIndexObj.index) {
          defaultIndex = setDefaultIndexObj.index;
        } else {
          defaultIndex = this.findListIndex(
            add,
            setDefaultIndexObj.selectAreaId
          );
        }
        defaultIndex = defaultIndex == -1 ? 0 : defaultIndex + 1;
      }
      return {
        values: [defaultItem, ...add],
        defaultIndex: defaultIndex
      };
    },
    findListIndex(list, id) {
      return list.findIndex((item) => item.id == id);
    }
  }
};
</script>

<style scoped>
.pullWrapbg /deep/ .van-picker__loading {
  background-color: rgba(255, 255, 255, 0.5);
}
</style>